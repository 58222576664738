<template>
  <div />
</template>
<script>
import store from '@/store';
import { getQueryStringArgs } from '@/lib/until';
import { mapActions } from 'vuex';
export default {
  created() {
    // 获取字典
    this.init();
  },
  methods: {
    ...mapActions(['getdictList']),
    async init() {
      await this.getdictList();
      this.toJump();
    },
    toJump() {
      switch (this.$route.query.messageType) {
      case 'contractRenew':
        this.$router.push(
          {
            name: 'MessageContract',
            query: JSON.stringify(store.state.mulParameters) !== '{}' ? store.state.mulParameters : getQueryStringArgs()
          }
        );
        break;
      case 'busToFollow':
        this.$router.push(
          {
            name: 'MessageFollow',
            query: JSON.stringify(store.state.mulParameters) !== '{}' ? store.state.mulParameters : getQueryStringArgs()
          }
        );
        break;
      case 'custChange':
        this.$router.push(
          {
            name: 'MessageCust',
            query: JSON.stringify(store.state.mulParameters) !== '{}' ? store.state.mulParameters : getQueryStringArgs()
          }
        );
        break;
      case 'custGroup':
        this.$router.push(
          {
            name: 'MessageGroup',
            query: JSON.stringify(store.state.mulParameters) !== '{}' ? store.state.mulParameters : getQueryStringArgs()
          }
        );
        break;
      }
    }
  }
};
</script>
